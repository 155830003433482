<template>

    <v-app id="bg_four">
        <div id="layout_top_four">
            
        </div>
        <div class="four_content d-flex flex-column justify-space-between align-center pa-10">
            <v-row align="stretch">
                <v-col cols="12" class="d-flex flex-column align-center pt-10">
                    <h3 style="color:white;" class="text-center mt-10 t_soal">{{ questions.text }}</h3>

                    <b-button v-for="(response, index) in questions.responses" 
                        @click="saveAnswer(index)"
                        :key="index"
                        pill
                        block
                        size="lg"
                        class="mt-5 shadow"
                        style="background-color:white;height:45px; font-size:10px;border-color:white;color:#3E8AC6;">
                        <span class="text_btn">
                            {{ response.text}}
                        </span>
                    </b-button>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../../assets/fonts/Poppins-Medium.ttf');
    }
    #bg_four {
        width: 100%;
        height: 100%;
        background: #3E8AC6;
    }
    .four_content{
        z-index:6;
        margin-top:90px;
    }
    .t_soal{
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    }
    .t_result{
        font-weight: 400;
        font-style: italic;
        font-size:9px;
    }
    .text_btn{
        font-weight: 600;
        font-style: normal;
        font-size:12px;
    }
    #layout_top_four {
        position: fixed;
        width: 100%;
        height: 100%;
        background: url('../../assets/images/bg.png') no-repeat;
        background-size: 100%;  
    }
    @media (min-width : 1000px) {
        #layout_top_four{
            width : 100%;
            height : 100%;
            position :fixed;
        }
    }
</style>

<script>
export default {
  name: 'QuestionFour',
  data: () => ({
     questions: {
        text: "Apa hal yang bisa membuatmu tidur nyenyak ?",
        responses: [
            { text: "Tubuh yang bersih setelah mandi dan gosok gigi" },
            { text: "Segelas susu hangat dan kue manis penghantar tidur" },
            { text: "Makan junk food dan fast food" }
        ]
     }
  }),
  methods: {
    saveAnswer (index) {
        localStorage.setItem('4', index)
        this.$router.push({ name: 'QuestionFive'})
    },
    changeColor(){
        var metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute("content", "#3E8AC6");
    }
    },
    created() {
        this.changeColor();
    },
}
</script>
